@use "uswds-core" as *;

.usa-header {
    background-color: color("violet-90"); 

    + main {
        border-top: 0; 
    }

    .usa-navbar {
        min-height: units(8);
        max-width: units('desktop-lg');

        .usa-logo {
            margin-top: units(2);
            margin-bottom: units(1);
            line-height: 1;
            font-size: units(1);
        
            span {
                text-indent: -9999px; 
            }

            img {
                width: 72px; 
                height: 36px;
            };

            .usa-sr-only {
                color: white;
            }
        }
        .usa-menu-btn {
            background-color: color('cyan-20v'); 
            color: color("violet-warm-90"); 
            border-radius: 0; 
            font-weight: 500; 
            font-size: size('body', 6);
            position: relative;
            height: units(8);
            width: units(10);
        }
    }

    .usa-nav-container {
        max-width: units('desktop-lg');
        align-items: center;
    }

    .usa-nav {
        z-index: 401;

        @include at-media(desktop) {
            padding: 0; 
        }

        &.is-visible {
            animation: none;
            background-color: color("violet-90"); 

            @include at-media(desktop) {
                min-width: 50%; 
            }      
        }

        &__primary {
            order: 0; 
            margin-bottom: units(3);
            @include at-media(desktop) {
                margin-bottom: 0; 
            }
            > .usa-nav__primary-item {
                padding: units(1) 0;
                a {
                    padding: units(2) units(3);
                }
                &:last-child {
                    border-bottom: 1px solid color('gray-cool-1');
                }

                .usa-nav__link {
                    &.usa-current, &:hover {
                        text-decoration: underline;
                        text-decoration-thickness: units(2px);
                        text-underline-offset: units(0.5);
                        color: white;
                        background: transparent; 
                    }
                }
            }
            .usa-current::after, .usa-nav__link:hover::after {
                background-color: transparent;
                border: 0; 
                height: 0; 
            }
        }
        
        &__link-text {
            color: color('gray-cool-1');
        }

        .ideaLink {
            .usa-nav__link-text {
                color: color('violet-30v');
                font-weight: 600;
            }          
        }

        .usa-nav__footer {
            flex-direction: column;
            margin-top: units(4);
            .usa-button:not(.usa-button__search), .usa-search {
                width: units('mobile');
                margin-bottom: units(2);
                margin-right: 0;
            }
        }
        

        @include at-media(desktop) {
            &__primary > .usa-nav__primary-item {
                padding: 0 units(1) 0 0;
                a {
                    padding: units(2);

                    @include at-media("desktop") {
                        padding: units(2);       
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }

            &__link-text {
                font-weight: 400; 
                font-size: size('body', 7);
                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: units(2px);
                    text-underline-offset: units(0.5);
                    color: white;
                }
            }

            .usa-nav__footer {
                flex-direction: row;
                margin-top: 0; 
                .usa-button:not(.usa-button__search) {
                    margin-right: units(1);
                    width: auto;
                    margin-bottom: 0; 
                }
                .usa-search  {
                    width: units('card-lg');
                    margin-bottom: 0; 
                }
            }
        }
        
        &__footer {
            display: flex; 
            flex-direction: row; 
        }

        .usa-search {
            .usa-input {
                background: transparent; 
                color: white;
                border: 2px solid color('cyan-20v');
                height: 40px; 

                &::placeholder {
                    color: white;
                }
            }
            button {
                height: 40px; 
            }
        }
    }

    .usa-nav__inner {
        max-width: units('desktop-lg');

        .contentWrapper {
            display: flex;
            flex-direction: column;

            @include at-media("desktop") {
                flex-direction: row;       
            }
        }

        .cta-buttons {
            @include at-media("desktop") {
                margin-left: units(2);      
            }            
        }

        .project-button {
            background: transparent;
            padding: units(0.5) units(1);
            border-color: white;
            box-shadow: inset 0 0 0 units(2px) white;
            color: white;
            height: units(5);
            display: inline-block;
            margin: units(2) 0 0;
            cursor: pointer;
            display: block;

            @include at-media(desktop) {
                margin: units(1) 0 0;
            }

            &::before,
            &::after {
              border-color: color('cyan-10v');
            }

            &:hover {
                color: color('cyan-10v');
                box-shadow: inset 0 0 0 units(2px) color('cyan-10v');

                span {
                    color: color('cyan-10v');
                }
            }

            &:focus {
                outline: units(1px) auto color('cyan-10v');
                color: color('cyan-10v');
                box-shadow: inset 0 0 0 units(2px) color('cyan-10v');

                span {
                    color: color('cyan-10v');
                }
            }

            span {
                color: white;
                padding: 0;

                &:hover {
                    color: color('cyan-10v');
                }
            }

            span,
            .usa-icon {                    
                display: inline-block;
                vertical-align: middle;
            }

            .usa-icon  {
                width: units(4);
                height: units(4);
            }
        }
    }

    .usa-search[role=search], 
    .usa-search[role=search]>div, 
    .usa-search [role=search] {
        float: none;
    }
}
