@use "uswds-core" as *;

$homepage-deep-blue: #160449;

html {
    scroll-behavior: smooth;
}

body {
    color: color("violet-90");
    font-size: size('body', 8);
    line-height: lh('body', 5);
    -webkit-font-smoothing: antialiased;
    min-width: units('mobile');
}

.usa-app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1;
    width: 100%;
}

img {
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
p {
    width: 100%;
    background-color: transparent;
}

h2 {
    color: color('indigo-warm-70v');
}

p,
.usa-identifier__required-links-list li {
    line-height: lh('body', 5);
}

a,
.usa-link {
    color: color('indigo-warm-60v');

    &--external::after {
        display: inline-block;
        margin-top: 0;
    }
}

em,
.text-italic {
    font-family: "Poppins-Italic", Arial, Helvetica, sans-serif;
}

.usa-tag {
    padding: 0;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;

    a {
        background: color('indigo-warm-10v');
        border: 1px solid color('indigo-warm-20v');
        color: color('indigo-warm-70v');
        text-decoration: none;
        padding: units(1px) units(1);
        display: block;

        &:hover {
            border: 1px solid color('indigo-warm-40v');
        }
    }

}

.usa-pagination {
    justify-content: left;

    .usa-pagination__button {
        border-radius: 0;
    }

    .usa-pagination__button,
    .usa-pagination__link {
        color: color('indigo-warm-60v');

        &:visited,
        &:active {
            color: color('indigo-warm-60v');
        }

        &.usa-current {
            background-color: color('indigo-warm-60v');
            color: white;
        }
    }
}

.usa-identifier {
    background: $homepage-deep-blue
}

h2,
.usa-identifier__required-links-list li {
    font-weight: 500;
}

.usa-identifier__required-links-list {
    column-count: 1;
    -moz-column-count: 1;

    @include at-media("tablet") {
        column-count: 5;
        -moz-column-count: 5;
    }
}

.usa-identifier__required-link,
.usa-identifier__required-link.usa-link {
    padding: units(1) 0;

    @include at-media("tablet") {
        padding: 0;
    }
}

.usa-identifier__container,
.usa-identifier__identity-domain,
.usa-identifier__required-link,
.usa-identifier__required-link.usa-link {
    color: white;
}

.usa-identifier__container {
    padding-left: 0;
    padding-right: 0;
}

.usa-identifier__section--usagov {
    background-color: color("violet-90");
}

.banner-bg {
    color: white;
    position: relative;

    .banner-bg-gradient {
        background: $homepage-deep-blue url('../images/homepage-gradient-bg-mobile.jpg') top left repeat-x;

        @include at-media("tablet") {
            background: $homepage-deep-blue url('../images/homepage-gradient-bg.jpg') top left repeat-x;
        }
    }

    .grid-container {
        position: relative;
        z-index: 5;
    }
}

.usa-section {
    padding: units(4);
}

.usa-card {
    display: inline-flex;

    &__container {
        margin-left: 0;
        margin-right: 0;
    }
}

.usa-breadcrumb__list-item {
    &.usa-current {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60%;
        display: inline-block;
        vertical-align: middle;
    }
}

.usa-home {
    .header-section-column {
        a.usa-link {
            color: color("gray-1");
        }
    }
}

.usa-hero {
    background: white;
    background-image: none;
    padding: units(3) units(5);

    h2,
    h3 {
        color: color("violet-90");
    }

    h2 {
        font-size: size('body', 10);
        line-height: lh('heading', 2);
        font-weight: 700;
        margin: 0.4em 0;

        @include at-media("tablet") {
            font-size: size('body', 13);
        }

        @include at-media("desktop-lg") {
            font-size: size('body', 14);
        }
    }

    h3 {
        font-size: size('body', 8);
        line-height: lh('heading', 4);
        font-weight: 400;
        margin: 1em 0;


        @include at-media("tablet") {
            font-size: size('body', 9);
        }

        @include at-media("desktop") {
            font-size: size('body', 11);
        }
    }

    .date-wrapper {
        font-size: size('body', 7);
        text-transform: uppercase;
        color: color('indigo-warm-60v');
    }
}

.usa-card__container {
    border-width: units(1px);
    border-color: color("violet-90");
    border-radius: 0;
}

.list-page-blue-background {
    background: #160449;
}

.homepage-wrapper {
    .banner-bg {

        h1 {
            font-size: size('body', 12);
            line-height: lh('heading', 3);
            font-weight: 700;
            margin: 0.4em 0;

            @include at-media("tablet") {
                font-size: size('body', 15);
            }
        }

        .banner-bg-gradient {
            background-size: 130% 60%;
        }

        .hero-section {
            padding: units(5);
        }

        .news-section {
            padding: 0;
        }
    }

    .header-section {
        padding: units(8);

        @include at-media("desktop-lg") {
            padding: units(8) units(5);
        }

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background: url('../images/tts-inclusive-grid-map.svg') center units(4) no-repeat;
            background-size: 60%;

            @include at-media("desktop") {
                background: url('../images/tts-inclusive-grid-map.svg') right units(1) no-repeat;
                background-size: 40%;
            }
        }

        .grid-row {
            z-index: 6;
        }

        h2,
        .h2 {
            font-size: size('body', 13);
            font-weight: 700;
            line-height: lh('heading', 2);
            text-align: center;
            color: color('cyan-30v');

            @include at-media("desktop") {
                font-size: size('body', 14);
                width: 80%;
                text-align: left;
            }
        }

        .grid-gap-6 {
            h3 {
                font-size: size('body', 10);
                line-height: lh('heading', 3);
                font-weight: 500;
            }
        }

        .header-section-column {
            @include at-media("desktop") {
                margin-top: units(4);
            }
        }
    }

    .usa-section {
        .usa-card {

            h2,
            .h2 {
                font-weight: 500;
                line-height: lh('heading', 2);
                text-transform: uppercase;
                color: color('indigo-warm-60v');
            }
        }
    }

    .section1 {
        background: white;

        @include at-media("desktop") {
            .grid-row:first-child {
                flex-direction: row-reverse;
            }
        }

        h2,
        h3 {
            font-weight: 500;
        }


        h2 {
            font-size: size('body', 10);
            line-height: lh('heading', 4);

            @include at-media("desktop") {
                font-size: size('body', 11);
            }
        }

        h3 {
            font-size: size('body', 10);
            line-height: lh('heading', 3);
            color: color('indigo-warm-60v');

            @include at-media("desktop") {
                font-size: size('body', 12);
            }
        }

        p {
            font-size: size('body', 8);
            font-weight: 400;
            line-height: lh('body', 5);

            @include at-media("desktop") {
                font-size: size('body', 9);
            }
        }

        .usa-button {
            font-size: size('body', 5);
            font-weight: 500;
        }

        .usa-card {

            h2,
            .h2 {
                font-size: size('body', 4);
            }

            p {
                font-size: size('body', 10);
                font-weight: 600;
                line-height: lh('body', 3);

                @include at-media("desktop") {
                    font-size: size('body', 11);
                }
            }

            .usa-card__header {
                padding: units(4) units(4) 0 units(4);

                @include at-media("desktop") {
                    padding: units(6) units(7) 0 units(7);
                }
            }

            .usa-card__body {
                padding: units(4) units(4) 0 units(4);

                @include at-media("desktop") {
                    padding: units(2.5) units(7) 0 units(7);
                }
            }

            .usa-card__footer {
                padding: units(4);
                margin: 0;

                @include at-media("desktop") {
                    padding: units(5) units(7);
                }
            }
        }

        .usa-identifier__required-links-item {
            font-size: size('body', 8);
            line-height: lh('body', 6);
        }

        .usa-media-block__img {
            width: 100%;
            height: auto;
            max-width: 515px;
            max-height: 435px;

            @include at-media("tablet") {
                margin: units(2) auto;
                display: block;
            }
        }
    }

    .section2 {
        background: url('../images/section2-purple-bg.webp') left top repeat-x;

        h2 {
            font-size: size('body', 12);
            line-height: lh('heading', 2);
            font-weight: 700;
            color: white;
            margin: 0 0 units(5);

            @include at-media("tablet") {
                font-size: size('body', 13);
            }

            @include at-media("desktop") {
                font-size: size('body', 15);
            }
        }

        &.usa-section {
            padding-top: units(7);
            padding-bottom: units(7);
        }

        .usa-button {
            font-size: size('body', 9);
            line-height: lh('body', 1);
        }
    }

    .section3 {
        background: white;

        h2 {
            font-size: size('body', 11);
            line-height: lh('heading', 4);

            @include at-media("desktop") {
                font-size: size('body', 12);
            }
        }

        .usa-card {
            .usa-card__container {
                .usa-card__body h3 {
                    display: flex;
                    align-items: center;

                    img {
                        width: 40px;
                        height: auto;
                        margin-right: units(1);
                    }

                    span {
                        padding: 4px 0;
                    }
                }

                &:hover {
                    .usa-card__body {
                        h3 {
                            span {
                                padding: 4px 0 2px;
                                border-bottom: 2px solid color("violet-90");
                            }
                        }
                    }
                }
            }

            h2,
            .h2 {
                font-size: size('body', 7);
            }

            h3 {
                font-size: size('body', 10);
                line-height: lh('heading', 3);
                font-weight: 500;


                @include at-media("desktop") {
                    font-size: size('body', 11);
                }
            }

            p {
                font-size: size('body', 7);
                line-height: lh('body', 5);
            }

            a {
                display: block;
                text-decoration: none;
            }

            span {
                line-height: lh('heading', 1);
            }

            p {
                margin: 0;
            }

            .usa-card__header,
            .usa-card__body {
                padding: 0;
            }

            .usa-card__header {

                h2,
                .h2 {
                    padding: units(4) units(4) 0 units(4);
                    color: color('indigo-warm-60v');

                    @include at-media("desktop") {
                        padding: units(5) units(5) 0 units(5);
                    }
                }
            }

            .usa-card__body {
                a {
                    color: color("violet-90");
                }

                h3 {
                    margin-bottom: 0;
                    padding: units(4);

                    @include at-media("desktop") {
                        padding: units(2.5) units(5) units(4);
                    }
                }

                p {
                    padding: 0 units(4) units(4) units(4);

                    @include at-media("desktop") {
                        padding: 0 units(5) units(5) units(5);
                    }
                }
            }

            .usa-card__footer {
                padding: units(4);
                margin: 0;

                @include at-media("desktop") {
                    padding: units(5);
                }
            }
        }
    }

    .section4 {
        background-color: color('cyan-20v');
        text-align: center;

        &.usa-section {
            padding: units(4);

            @include at-media("desktop") {
                padding: units(8);
            }
        }

        h2 {
            font-size: size('body', 10);
            line-height: lh('heading', 2);
            font-weight: 700;
            margin: 0 0 units(6);
            color: color("violet-90");

            @include at-media("tablet") {
                font-size: size('body', 12);
            }
        }

        span {
            color: color("indigo-warm-60v");
        }

        .usa-button {
            background: color("indigo-warm-60v");
            font-size: size('body', 9);
            line-height: lh('body', 1);
            margin-left: auto;
            margin-right: auto;

            a,
            a:visited,
            a:active,
            span {
                color: white;
                padding: units(2) units(4);
            }

            &:visited {
                color: color("indigo-warm-70v");
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                border-color: color('indigo-warm-20v');
                border-style: solid;
                transition: all 0.35s;
                content: "";
                z-index: 1;
            }

            &::before {
                width: 0;
                height: 100%;
                border-width: 1px 0 1px 0;
            }

            &::after {
                width: 100%;
                height: 0;
                border-width: 0 1px 0 1px;
            }

            &:hover {
                border-color: color('indigo-warm-20v');
                background-color: color('indigo-warm-70v');
                transition: background-color .6s ease;

                &::before {
                    width: 100%;
                    border-width: 1px 0 1px 0;
                }

                &::after {
                    height: 100%;
                    border-width: 0 1px 0 1px;
                }
            }
        }

        form {
            margin: 0 auto;
        }
    }

    .section5 {
        color: white;

        h2 {
            font-size: size('body', 11);
            line-height: lh('heading', 4);
            color: color('cyan-20v');
        }

        h3 {
            font-size: size('body', 9);
            line-height: lh('heading', 5);
            font-weight: 500;
        }

        p {
            font-size: size('body', 5);

            .usa-link {
                color: white;
            }
        }

        ul {
            padding-left: units(3);
            width: 100%;

            @include at-media("tablet") {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }

            @include at-media("desktop") {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
            }

            li {
                font-size: size('body', 7);
            }
        }
    }
}

.linear-gradient {
    background: linear-gradient(102.06deg, rgba(0, 143, 143, 0.8) 10.05%, rgba(33, 33, 207, 0.8) 35.69%, rgba(58, 51, 123, 0.8) 61.32%, rgba(171, 79, 228, 0.8) 86.96%),
        linear-gradient(0deg, #6F32DD, #6F32DD);
}

.section-prefooter.usa-section {
    padding: units(1) 0 0 0;
}

.prefooter-wrapper {
    background: color("indigo-warm-60v");

    h3,
    .usa-link {
        color: white;
    }

    h3,
    li {
        font-size: size('body', 5);
        line-height: lh('heading', 6);
        margin-bottom: units(2.5);
    }
}

.portfolio-blog-pages {
    .banner-bg {
        color: white;

        .banner-bg-gradient {
            background-size: 150% 150%;
        }

        .header-section {
            display: flex;
            flex-direction: column;
            padding-top: 0;

            h1 {
                font-size: size('body', 11);
                font-weight: 700;
                line-height: lh('heading', 3);

                @include at-media("tablet-lg") {
                    font-size: size('body', 14);
                    margin-top: units(7);
                    margin-bottom: 0;
                }

                span {
                    text-transform: lowercase;
                    display: inline-block;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            h2,
            .intro {
                font-size: size('body', 9);
                font-weight: 400;
                line-height: lh('heading', 4);

                @include at-media("tablet-lg") {
                    font-size: size('body', 10);
                }
            }

            h1,
            h2 {
                color: white;
            }

            .page-brow {
                font-size: size('body', 7);
                text-transform: uppercase;
                font-weight: 400;
                padding-bottom: units(1.5);
                line-height: 1.2;
                color: color('cyan-10v');
                background: url('../images/header-bottom-border.webp') left bottom no-repeat;
                margin-bottom: 0;
                margin-top: units(3);

                &.blog-post-section-header {
                    background: none;
                }
            }

            &.blog-post-header-section {

                h4 {
                    background: none;
                }
            }
        }
    }

    p {
        &.disclaimer {
            font-size: size('body', 1);
        }
    }
}

.portfolio-bloglist-wrapper {
    .portfolio-blog-list {
        margin-top: units(6);

        .portfolio-blog-card,
        .usa-card__container {
            width: 100%;
        }
    }

    .usa-card--flag {
        h2 {
            font-size: size('body', 7);
            font-weight: 400;
            text-transform: uppercase;
        }

        h3 {
            font-size: size('body', 8);
            font-weight: 500;
            line-height: lh('heading', 5);

            .usa-link {
                color: color("violet-90");
            }

            @include at-media("tablet-lg") {
                font-size: size('body', 10);
            }
        }

        .usa-link {
            text-decoration: none;
        }

        .usa-card__header,
        .usa-card__body,
        .usa-card__footer {
            @include at-media("tablet") {
                margin-left: 17rem;
            }
        }

        .usa-card__header {
            padding-top: units(5);
            padding-left: units(5);
            padding-right: units(5);
        }

        .usa-card__header {
            padding-top: units(5);
        }

        .usa-card__header,
        .usa-card__body {
            padding-left: units(5);
            padding-right: units(5);
        }

        .usa-card__body {
            padding-bottom: units(5);
        }

        .usa-card__media {
            @include at-media("tablet") {
                width: 17rem;
            }
        }

        .usa-card__img {
            border-radius: 0;
            width: 100%;

            img {
                @include at-media("tablet") {
                    object-fit: unset;
                }
            }
        }
    }

    .newspage-wrapper {
        @include at-media("desktop") {
            .content-column {
                flex-direction: row-reverse;
                min-height: 40vh;
            }
        }

        h3 {
            margin-bottom: units(2);

            .usa-link {
                &:hover {
                    border-bottom: units(2px) solid color("violet-90");
                }
            }
        }

        .usa-card__header,
        .usa-card__body,
        .usa-card__footer {
            @include at-media("tablet") {
                margin-left: 0;
            }
        }

        .usa-card__body {
            p {
                &.excerpt-text {
                    font-size: size('body', 7);
                    margin: units(2) 0 0;
                }
            }
        }
    }

    .usa-card--flag:nth-of-type(even) {

        .usa-card__header,
        .usa-card__body,
        .usa-card__footer {
            @include at-media("tablet") {
                margin-right: 20rem;
                margin-left: 0;
            }
        }

        .usa-card__media {
            @include at-media("tablet") {
                left: auto;
                right: units(-2px);
            }
        }
    }

    &.impact-landing-page {
        a {
            text-decoration: none;
        }

        .usa-card {
            h3 {
                span {
                    border-bottom: 2px solid white;
                }
            }

            &:hover {
                h3 {
                    span {
                        border-bottom: 2px solid color("violet-90");
                    }
                }
            }
        }

        .usa-card__header,
        .usa-card__body,
        .usa-card__footer {
            margin-left: 0;
        }

        .usa-card--flag:nth-of-type(even) {

            .usa-card__header,
            .usa-card__body,
            .usa-card__footer {
                @include at-media("tablet") {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        .usa-card__header,
        .usa-card__body {
            background: white;
        }

        .usa-card__header {
            margin-top: units(2);
        }

        .usa-card__body {
            margin-top: -1px;
        }

        .current-year {
            .usa-card__header {
                margin-top: units(3)
            }
        }

        .fy23-card {

            .usa-card__header,
            .usa-card__body,
            .usa-card__footer {
                margin-right: 0;
            }

            .usa-card__header {
                margin-top: units(2);
            }

            .usa-card__body {
                @include at-media("desktop") {
                    width: 75%;
                }
            }

            .usa-card__media {
                bottom: 0;
                height: 96%;
                top: auto;
                background: white;

                @include at-media("desktop") {
                    width: 25%;
                }

                @include at-media-max('desktop') {
                    display: none;
                }

                .usa-card__img {
                    background: none;
                    padding: units(4);
                    margin-top: units(10);

                    img {
                        object-fit: initial;
                    }
                }
            }

        }

        &.impact-landing1 {
            @include at-media("desktop") {
                .fy23-card {
                    .usa-card__header {
                        margin-top: 0;
                    }

                    .usa-card__media {
                        height: 100%;
                    }
                }
            }
        }

        &.impact-landing1,
        &.impact-landing2 {

            .fy23-card {
                .usa-card__container {
                    background: rgba(0, 189, 227, 1);
                    background: linear-gradient(90deg, rgba(0, 189, 227, 1) 0%, rgba(171, 79, 228, 1) 100%);
                }
            }

            .fy22-card {
                .usa-card__container {
                    background: rgba(69, 217, 217, 1);
                    background: linear-gradient(90deg, rgba(69, 217, 217, 1)0%, rgba(181, 240, 240, 1) 25%, rgba(35, 203, 222, 1) 50%, rgba(181, 240, 240, 1) 75%, rgba(69, 217, 217, 1) 100%);
                }
            }

            .fy21-card {
                .usa-card__container {
                    background: rgb(245, 121, 136);
                    background: linear-gradient(90deg, rgba(245, 121, 136, 1) 0%, rgba(255, 183, 228, 1) 25%, rgba(171, 79, 228, 1) 50%, rgba(255, 183, 228, 1) 75%, rgba(245, 121, 136, 1) 100%);
                }
            }

            .fy20-card {
                .usa-card__container {
                    background: rgba(0, 189, 227, 1);
                    background: linear-gradient(90deg, rgba(0, 189, 227, 1) 0%, rgba(171, 79, 228, 1) 100%);
                }
            }

            .fy19-card {
                .usa-card__container {
                    background: rgba(69, 217, 217, 1);
                    background: linear-gradient(90deg, rgba(69, 217, 217, 1)0%, rgba(181, 240, 240, 1) 25%, rgba(35, 203, 222, 1) 50%, rgba(181, 240, 240, 1) 75%, rgba(69, 217, 217, 1) 100%);
                }
            }

            .fy18-card {
                .usa-card__container {
                    background: rgb(245, 121, 136);
                    background: linear-gradient(90deg, rgba(245, 121, 136, 1) 0%, rgba(255, 183, 228, 1) 25%, rgba(171, 79, 228, 1) 50%, rgba(255, 183, 228, 1) 75%, rgba(245, 121, 136, 1) 100%);
                }
            }

            .fy17-card {
                .usa-card__container {
                    background: rgba(0, 189, 227, 1);
                    background: linear-gradient(90deg, rgba(0, 189, 227, 1) 0%, rgba(171, 79, 228, 1) 100%);
                }
            }

        }

        &.impact-landing3 {
            .fy23-card {
                .usa-card__container {
                    background: rgba(171, 79, 228, 1);
                }
            }

            .fy22-card {
                .usa-card__container {
                    background: rgba(0, 189, 227, 1);
                }
            }

            .fy21-card {
                .usa-card__container {
                    background: rgba(69, 217, 217, 1);
                }
            }

            .fy20-card {
                .usa-card__container {
                    background: rgba(171, 79, 228, 1);
                }
            }

            .fy19-card {
                .usa-card__container {
                    background: rgba(0, 189, 227, 1);
                }
            }

            .fy18-card {
                .usa-card__container {
                    background: rgba(69, 217, 217, 1);
                }
            }

            .fy17-card {
                .usa-card__container {
                    background: rgba(171, 79, 228, 1);
                }
            }
        }
    }

    .usa-card__container {
        min-height: auto
    }
}

.static-content-page {

    @include at-media("desktop") {
        .content-column {
            flex-direction: row-reverse;
            min-height: 40vh;
        }
    }

    a {
        color: color('indigo-warm-60v');

        &:hover {
            color: color('indigo-warm-70v');
        }
    }

    h2,
    h3,
    h4 {
        line-height: lh('heading', 6);
    }

    h3,
    h4 {
        font-weight: 500;
    }

    h2 {
        font-size: size('body', 12);
        font-weight: 600;

        &:first-child {
            margin-top: units(1.5);
        }
    }

    h3 {
        font-size: size('body', 11);
        color: color('indigo-warm-70v');
        margin-top: units(5);
    }

    h4 {
        font-size: size('body', 9);
        color: color('indigo-warm-80v');
    }

    p {
        font-size: size('body', 6);
        line-height: lh('body', 6);
        max-width: 100%;
        margin-top: units(1.5);

        &.usa-intro {
            margin-top: units(-0.5);
            font-size: size('body', 12);
            font-weight: 600;
            line-height: lh('body', 4);

            &.introBackground {
                font-weight: 400;
                padding: units(2) units(3);
                background: color('cyan-5v');
                margin-top: units(1);
            }
        }

        &.back-to-news-wrapper {
            margin: units(4) 0 units(2);
        }
    }

    &.blog-page {
        p {
            &.usa-intro {
                margin-top: units(1.5);
            }
        }
    }

    hr {
        margin: units(5) 0;
        max-width: 132px;
        border: none;
        border-top: 7px solid color("indigo-warm-60v");
        height: 7px;
    }

    .usa-button {
        background: color("indigo-warm-60v");
        border-color: color('indigo-warm-70v');
        font-weight: 600;
        margin-top: units(4);

        &::before,
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            border-color: color('indigo-warm-70v');
            border-style: solid;
            transition: all 0.35s;
            content: "";
            z-index: 1;
        }

        &::before {
            width: 0;
            height: 100%;
            border-width: 1px 0 1px 0;
        }

        &::after {
            width: 100%;
            height: 0;
            border-width: 0 1px 0 1px;
        }

        &:hover {
            border-color: color('indigo-warm-70v');
            background-color: color('indigo-warm-60v');
            transition: background-color .6s ease;

            &::before {
                width: 100%;
                border-width: 1px 0 1px 0;
            }

            &::after {
                height: 100%;
                border-width: 0 1px 0 1px;
            }
        }

        a,
        a:active,
        a:visited {
            color: white;
            text-decoration: none;
            position: relative;
            z-index: 5;
        }
    }

    h3[tabindex]:focus {
        outline: none;
        padding: 0;
    }
}

.usa-prose>*+* {
    margin-top: units(3);
}

iframe:focus,
[href]:focus,
[tabindex]:focus,
[contentEditable=true]:focus {
    outline: units(1px) auto color('indigo-warm-60v');
    outline-offset: 0.3rem;
}

.return-to-top {
    transition: all .5s ease-in-out;
    font-size: size('body', 5);
    margin: 0 0 units(2.5) 0 !important;

    &.fixed {
        position: fixed;
        bottom: 30px;
        transition: all .5s ease-in-out;
        z-index: 1000;
    }
}

.sampleText {
    background: color('indigo-warm-5v');
    padding: units(3);
}

.usa-sidenav {
    border-bottom: units(1px) solid color('indigo-warm-70v');

    .usa-sidenav__item {
        border-top: none;
        border-bottom: units(1px) solid color('indigo-warm-70v');

        a {
            font-size: size('body', 8);
            font-weight: 500;
            border: none;
            padding: units(2.5) units(2) units(2.5) units(4);
            color: color('indigo-warm-70v') !important;
            background: white;

            &:hover,
            &:active {
                background: color('blue-5');
                color: color('indigo-warm-80v') !important;
            }
        }

        &:first-child {
            border-top: units(1px) solid color('indigo-warm-70v');

            // a {
            //     background: url('../images/left-arrow.svg') left units(0.5) no-repeat;
            //     padding: units(1px) units(2) units(1) units(4);
            //     font-size: size('body', 5);
            // }
        }

        &.usa-current-list-item {
            .usa-current {
                background: color('cyan-5v');
                border-top: 5px solid color('cyan-20v');
                font-weight: 600;

                &:after {
                    background: none;
                    width: 0;
                }
            }
        }

        .usa-sidenav__sublist {
            ol {
                padding: 0;
                list-style: none;
                border: none;

                li {
                    border-bottom: units(1px) solid color('indigo-warm-70v');

                    &:last-child {
                        border: none;
                    }

                    a {
                        font-size: size('body', 7);
                        font-weight: 400;
                        padding: units(2) units(2) units(2) units(7);
                        border: none;
                    }
                }
            }
        }
    }
}

// pull quote
aside.pull-quote {
    padding: units(1) units(3);
    margin: units(5) 0;
    border-left: 7px solid color("indigo-warm-60v");
    font-size: size('body', 8);
    font-weight: 600;
    font-style: italic;

    span.author {
        display: block;
        font-style: normal;
        margin-top: units(1);
    }
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: units(1.5);
    width: units(1.5);
    margin-left: units(0.5);
    background-size: units(1.5) units(1.5);
    background-repeat: no-repeat;
    background-image: url(../images/menu-close-X.webp);
    cursor: pointer;
}

/* Touchpoint button */
#feedbackFormButton.usa-button {
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.06rem;
    line-height: 0.8;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: white;
    background-color: #005ea2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-top: units(1px) solid white;
    border-left: units(1px) solid white;
    border-right: units(1px) solid white;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 0.5rem;
    padding: 0.75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
    position: fixed;
    right: 12px;
    z-index: 9999;
    width: auto;

    @include at-media("tablet") {
        line-height: 0.9;
    }

    &:hover {
        transition: background-color 0.6s ease;
    }

    &::before,
    &::after {
        border-top-left-radius: units(0.5);
        border-top-right-radius: units(0.5);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    svg,
    span {
        vertical-align: middle;
    }

    span {
        padding: 0;
        color: white;
        display: inline-block;
    }

    svg {
        margin-right: units(2px);
        width: units(2.5);
        height: units(2.5);
    }
}

.rightSideFeedbackButton #feedbackFormButton.usa-button {
    bottom: 40%;
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    transform: rotate(270deg);
    -ms-transform-origin: right bottom;
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    transform-origin: right bottom;
    right: units(-1);
}

.purpleFeedbackButton #feedbackFormButton.usa-button {
    background: color("indigo-warm-60v");
}

.verticalIcon svg {
    transform: rotate(90deg);
}

.cyanButton {
    #feedbackFormButton.usa-button {
        background-color: #52daf2;

        span {
            color: black;
        }

        &:hover {
            background-color: color('cyan-10v');
        }

    }
}

.fba-modal-dialog {
    .usa-button {
        &::before,
        &::after {
            border-radius: 0.25rem;
        }
      }
}

.fba-usa-modal-wrapper {
    .fba-modal {
        .fba-usa-modal__content {
            .fba-usa-modal__close {
                padding: units(0.5) units(0.5) units(0.5) units(1.5) !important;

                &::before,
                &::after {
                    border: none;
                }
            }
        }
    }
}