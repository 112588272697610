@use "uswds-core" as *;

.usa-button {
    background-color: color('cyan-20v'); 
    color: color("violet-warm-90"); 
    border-radius: 0; 
    font-weight: 500; 
    font-size: size('body', 5);
    position: relative;
    padding: 0;

    .usa-link, a, a:visited, a:active, span {
      color: color("violet-warm-90");
    }

    &button, &a, a, a:visited, a:active, span {
      padding: units(2);
    }

    a, a:visited, a:active, span {     
      text-decoration: none;
      position: relative;     
      z-index: 5;
      display: block;
    }

    &:visited,
    &:active,
    &:focus {
      color: color("violet-warm-90"); 
      background-color: color('cyan-20v'); 
    }

    &:visited {
        color: color("violet-warm-90"); 
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      border-color: color('cyan-50v');
      border-style: solid;
      transition: all 0.35s;
      content: "";
      z-index: 1;
    }
    &::before {
      width: 0;
      height: 100%;
      border-width: 1px 0 1px 0;
    }
    &::after {
      width: 100%;
      height: 0;
      border-width: 0 1px 0 1px;
    }

    &:hover {
      border-color: color('cyan-50v');
      color: black;
      background-color: color('cyan-10v');
      transition: background-color .6s ease;

      &::before {
        width: 100%;
        border-width: 1px 0 1px 0;
      }

      &::after {
        height: 100%;
        border-width: 0 1px 0 1px;
      }
    }

    &.ideaLink {
      background: transparent;  
      border: units(2px) solid color('cyan-10v');
      margin-left: units(1);
      height: units(5);
      border-radius: units(0.5);

      &:hover {
        border: units(2px) solid white;
        
        a {
          color: white;
        }
      }
      
      a {
        color: color('cyan-10v');
        display: block;
        padding: units(1.5);
        margin-top: units(-2px);
      }
    }

    span {
      &.usa-sr-only {
          position: absolute;
      }        
    }
}